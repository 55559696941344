import vueMainConfig from "./vueMainConfig";
import { CHATBOT_CONTAINER_ID } from "./constants";

setTimeout(function() {
	const script = document.getElementById("vocalls-chat");

	if (script) {
		const lang = script.dataset.language;
		const customParams = [];

		for (const attr in script.dataset) {
			if (attr.startsWith("custom")) {
				customParams.push({ name: attr, value: script.dataset[attr] });
			}
		}

		window.vocallsChatbot = {
			key: script.dataset.key,
			apiEndpoint: script.dataset.apiendpoint,
			noCache: script.dataset.nocache,
			themeColor: script.dataset.themecolor,
			title: script.dataset.title,
			testSite: script.dataset.testsite,
			windowState: script.dataset.windowstate,
			noClose: script.dataset.noclose,
			language: lang ? lang.split("_")[0] : "en",
			enableVoiceInput: script.dataset.enablevoiceinput,
			recordTtsCode: script.dataset.recordttscode,
			recordTtsEngine: script.dataset.recordttsengine,
			openAfter: script.dataset.openafter,
			customParams
		};
	}

	const chatbotContainer = document.createElement("div");
	chatbotContainer.setAttribute("id", CHATBOT_CONTAINER_ID);
	document.body.appendChild(chatbotContainer);

	if (window.vocallsChatbot) {
		if (window.vocallsChatbot.testSite) {
			const testSiteIframe = document.createElement("iframe");
			testSiteIframe.setAttribute("src", window.vocallsChatbot.testSite);
			testSiteIframe.style.width = "100%";
			testSiteIframe.style.height = "100%";
			testSiteIframe.style.border = "none";

			document.body.style.height = "100vh";
			document.body.style.margin = "0";
			document.body.appendChild(testSiteIframe);
		}
	}

	vueMainConfig();
}, 1000);
