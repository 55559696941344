<template>
	<div class="vc-window-overlay" />
</template>

<script>
import "./WindowOverlay.scss";

export default {
	data() {
		return {};
	},
	name: "WindowOverlay",
	props: {},
	methods: {}
};
</script>
