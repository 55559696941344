<template>
	<ul class="vc-dropdown-menu">
		<li
			v-for="item in getMenuItems()"
			:key="item.id"
			class="vc-dropdown-menu__item"
			@click="item.action"
		>
			{{ item.label }}
		</li>
	</ul>
</template>

<script>
import { mapMutations } from "vuex";
import { apiInit } from "../../api";
import { getMessage } from "../../utils";
import { mapTranslation } from "../../localization";
import "./DropdownMenu.scss";

export default {
	data: ({ clearMessegesHandler, toggleChatbot }) => ({
		menuItems: [
			{
				id: 1,
				label: mapTranslation("startChatAgain"),
				action: clearMessegesHandler
			},
			{
				id: 2,
				label: mapTranslation("closeChat"),
				action: toggleChatbot
			}
		]
	}),
	name: "DropdownMenu",
	methods: {
		...mapMutations([
			"clearMessages",
			"toggleChatbot",
			"toggleDropdownMenu",
			"setMessageActiveInput",
			"setApiToken",
			"setConversationEnd"
		]),

		getMenuItems() {
			if (window.vocallsChatbot) {
				if (window.vocallsChatbot.noClose) {
					return [this.menuItems[0]];
				}
			}

			return this.menuItems;
		},

		clearMessegesHandler() {
			this.clearMessages();
			this.toggleDropdownMenu();
			this.setConversationEnd(false);

			apiInit().then(({ data }) => {
				this.setApiToken(data.sessionId);
				this.setMessageActiveInput(true);
				getMessage();
			});
		}
	}
};
</script>
