import store from "../store";
import {
	isMessageTextType,
	isMessageWaitType,
	isMessageConversationEnd,
	isMessageOptionType
} from "./getMessageType";
import {
	MSG_QUEUED_STATE,
	MSG_WAITING_STATE,
	MSG_RENDERED_STATE
} from "../constants";
import getWaitingTime from "./getWaitingTime";
import getMessage from "./getMessage";
import commitMessageToStore from "./commitMessageToStore";
import { mapTranslation } from "../localization";

const mapMessagesWithTyping = data => {
	store.commit("setMessageInputPlaceholder", mapTranslation("typeMessage"));

	if (data && isMessageConversationEnd(data[data.length - 1])) {
		store.commit("setConversationEnd", true);
	}

	data.map((message, index) => {
		message.allowTextInput === false
			? store.commit("setMessageActiveInput", false)
			: store.commit("setMessageActiveInput", true);

		const messageId = `${index}-${message.timestamp}`;
		const waitingTime = getWaitingTime(data, index + 1);

		const isLastInBlock = index + 1 >= data.length;
		const isFirstInBlock = index === 0;

		if (!isMessageWaitType(message)) {
			commitMessageToStore(message, { state: MSG_QUEUED_STATE, id: messageId });
		}

		if (
			(isFirstInBlock && isMessageTextType(message)) ||
			(isFirstInBlock && isMessageOptionType(message) && message.delay)
		) {
			store.commit("setMessageState", {
				id: messageId,
				messageState: MSG_WAITING_STATE
			});
		}

		setTimeout(() => {
			if (!isMessageWaitType(message)) {
				store.commit("setMessageState", {
					id: messageId,
					messageState: MSG_RENDERED_STATE
				});
			}

			if (isLastInBlock && !message.allowTextInput) {
				store.commit(
					"setMessageInputPlaceholder",
					mapTranslation("chooseOption")
				);
			}

			if (
				(!isLastInBlock && isMessageTextType(data[index + 1])) ||
				(!isLastInBlock &&
					isMessageOptionType(data[index + 1]) &&
					data[index + 1].delay)
			) {
				const {
					id: nextMessageId,
					state: nextMessageState
				} = store.getters.getNextMessage(messageId);

				nextMessageState === MSG_RENDERED_STATE
					? null
					: store.commit("setMessageState", {
							id: nextMessageId,
							messageState: MSG_WAITING_STATE
					  });
			}

			if (isLastInBlock && isMessageWaitType(message)) {
				getMessage();
			}

			if (isMessageConversationEnd(message)) {
				store.commit("setMessageActiveInput", false);
				store.commit("setMessageInputPlaceholder", mapTranslation("chatEnd"));
			}

			if (
				isLastInBlock &&
				!isMessageWaitType(message) &&
				!isMessageConversationEnd(message)
			) {
				store.commit("startMessageGetterInterval");
			}
		}, waitingTime);
	});
};

export default mapMessagesWithTyping;
