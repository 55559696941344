<template>
	<div v-show="value" class="vc-dialog-window">
		<div class="vc-dialog-window__heading">
			<div class="vc-dialog-window__title">{{ title }}</div>
			<IconButton iconType="cross" size="small" :actionHandler="toggleClose" />
		</div>
		<div><slot></slot></div>
		<div v-if="actions && actions.length > 0" class="vc-dialog-window__actions">
			<Button
				v-for="action in actions"
				:key="action.id"
				:label="action.label"
				:actionHandler="
					() => {
						action.handler();
						toggleClose();
					}
				"
				:activeColor="action.color"
				:isSelected="!!action.color"
			/>
		</div>
	</div>
</template>

<script>
import IconButton from "../IconButton";
import Button from "../Button";
import "./DialogWindow.scss";

export default {
	name: "DialogWindow",

	components: {
		IconButton,
		Button
	},

	props: {
		value: { type: Boolean, default: false },
		title: String,
		actions: { type: Array, default: () => [] }
	},

	methods: {
		toggleClose() {
			this.$emit("close");
		}
	}
};
</script>
