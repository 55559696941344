const messages = {
	en: {
		closeChat: "Close chat",
		startChatAgain: "Start chat again",
		typeMessage: "Type a message...",
		chooseOption: "Choose one option...",
		chatEnd: "Chat ended",
		voiceRecordStart: "Please start talking...",
		voiceRecordProcessing: "Processing..."
	},
	cs: {
		closeChat: "Zavřít chat",
		startChatAgain: "Začít chat znovu",
		typeMessage: "Napište zprávu...",
		chooseOption: "Vyberte jednu z možností...",
		chatEnd: "Konverzace ukončena",
		voiceRecordStart: "Prosím začněte mluvit...",
		voiceRecordProcessing: "Probíhá zpracování..."
	},
	sk: {
		closeChat: "Zavrieť chat",
		startChatAgain: "Znova začnite četovať",
		typeMessage: "Napíš správu...",
		chooseOption: "Vyberte jednu možnosť...",
		chatEnd: "Rozhovor sa skončil",
		voiceRecordStart: "Prosím začnite hovoriť...",
		voiceRecordProcessing: "Prebieha spracovanie..."
	},
	pl: {
		closeChat: "Zamknij czat",
		startChatAgain: "Rozpocznij czat ponownie",
		typeMessage: "Wpisz wiadomość...",
		chooseOption: "Wybierz jedną opcję...",
		chatEnd: "Czat zakończony",
		voiceRecordStart: "Proszę zacznij mówić...",
		voiceRecordProcessing: "Przetwarzanie..."
	},
	it: {
		closeChat: "Chiudere la chat",
		startChatAgain: "Ricomincia la chat",
		typeMessage: "Scrivi un messaggio...",
		chooseOption: "Scegliere un'opzione...",
		chatEnd: "Chat terminata",
		voiceRecordStart: "Per favore, inizia a parlare...",
		voiceRecordProcessing: "Elaborazione..."
	},
	al: {
		closeChat: "Bisedë e ngushtë",
		startChatAgain: "Fillo përsëri bisedën",
		typeMessage: "Shkruani një mesazh...",
		chooseOption: "Zgjidh një opsion...",
		chatEnd: "Biseda përfundoi",
		voiceRecordStart: "Ju lutem filloni të flisni ...",
		voiceRecordProcessing: "Procesimi..."
	},
	ro: {
		closeChat: "Închideți chat-ul",
		startChatAgain: "Începeți din nou discuția",
		typeMessage: "Introduceți un mesaj...",
		chooseOption: "Alegeți o opțiune...",
		chatEnd: "Chat încheiat",
		voiceRecordStart: "Vă rog să începeți să vorbiți...",
		voiceRecordProcessing: "Procesare..."
	},
	ne: {
		closeChat: "चट बन्द गर्नुहोस्",
		startChatAgain: "फेरि च्याट सुरु गर्नुहोस्",
		typeMessage: "एक सन्देश टाइप गर्नुहोस्...",
		chooseOption: "एउटा विकल्प रोज्नुहोस्...",
		chatEnd: "च्याट समाप्त भयो",
		voiceRecordStart: "कृपया कुरा गर्न सुरु गर्नुहोस् ...",
		voiceRecordProcessing: "प्रक्रिया गर्दै..."
	},
	ms: {
		closeChat: "Tutup sembang",
		startChatAgain: "Mulakan sembang semula",
		typeMessage: "Taip mesej...",
		chooseOption: "Pilih satu pilihan...",
		chatEnd: "Sembang ditamatkan",
		voiceRecordStart: "Sila mula bercakap...",
		voiceRecordProcessing: "Pemprosesan..."
	},
	th: {
		closeChat: "ปิดแชท",
		startChatAgain: "เริ่มแชทอีกครั้ง",
		typeMessage: "พิมพ์ข้อความ...",
		chooseOption: "เลือกหนึ่งตัวเลือก...",
		chatEnd: "แชทจบแล้ว",
		voiceRecordStart: "ได้โปรดเริ่มพูด...",
		voiceRecordProcessing: "กำลังประมวลผล..."
	}
};

export default messages;
