<template>
	<div :class="['vc-window-overlay', deep ? 'vc-window-overlay--deep' : '']" />
</template>

<script>
import "./WindowOverlay.scss";

export default {
	data() {
		return {};
	},
	name: "WindowOverlay",
	props: {
		deep: { type: Boolean, deafult: false }
	},
	methods: {}
};
</script>
