import axios from "axios";
import { getApiKey, getApiUrl, getUrlParameters } from "../utils";
import store from "../store";
import getBrowserParameters from "../utils/getBrowserParameters";

export const apiInit = () => {
	const customParameters = window.vocallsChatbot
		? window.vocallsChatbot.customParams
		: [];

	return axios.post(`${getApiUrl()}/${getApiKey()}/init`, {
		parameters: [...getUrlParameters(), ...customParameters],
		browserData: getBrowserParameters()
	});
};

export const getMessage = () =>
	axios.get(`${getApiUrl()}/${getApiKey()}/message`, {
		headers: {
			"Vocalls-SessionId": store.getters.apiToken
		}
	});

export const sendMessage = (value, type) =>
	axios.post(
		`${getApiUrl()}/${getApiKey()}/message`,
		{
			Type: type,
			Value: value
		},
		{
			headers: {
				"Content-type": "application/json",
				"Vocalls-SessionId": store.getters.apiToken
			}
		}
	);

export const getAdressFromCoords = ({ lat, lng }) =>
	axios.get(
		`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBb3mWAC23Tqw5IBhZ81Rj6HK3th49TQp8`
	);
